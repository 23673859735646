@import 'vars';

.loader {
  background: url('../../../assets/loader.svg') no-repeat center center;
  position: absolute;
  top: 80px;
  left: 0;
  height: calc(100% - 80px);
  width: 100%;
  z-index: 9999998;
}

.overlayLoader {
  background: var(--black) url('../../../assets/loader.svg') no-repeat center
    center;
  position: absolute;
  top: 80px;
  left: 0;
  height: calc(100% - 80px);
  width: 100%;
  z-index: 19;
}
